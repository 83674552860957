<template>
  <!-- 
    画面: プラン一覧
    用途: 宿泊プランの一覧を参照する
   -->
  <div>

    <!-- #region List -->
    <CRow>
      <CCol
        md="12"
        lg="12"
      >
        <CCard>
          <CCardHeader>
            <strong class="card-title mr-3">{{ $t("planListPage.pageTitle") }}</strong>
            <CButton
              color="info"
           
              @click="onCreateClicked()"
            >{{ $t("common.create") }}</CButton>
          </CCardHeader>
          <CCardBody>
            <PlanListTable />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <!-- #endregion List -->

  </div>
</template>

<script>
import PlanListTable from "./components/ListTable";

export default {

  name: "PlanList",

  components: {
    PlanListTable
  },

  methods: {

    //#region Event

    /** 新規作成ボタン押下 */
    onCreateClicked() {
      this.$router.push({
        // path: `/admin/plan/create` // TODO: 開発中
        path: `/operator/plan/create`
      });
    },

    /** 並び替え操作 */

    //#endregion Event

  }

}
</script>
