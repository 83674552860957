<template>
  <!-- 
    画面: プラン一覧テーブル
    用途: プラン一覧カードを一覧表示する
   -->
  <div>
    <Draggable
      class="list-group"
      v-model="tableItems"
      @change="onSortEnd"
      v-bind="dragOptions"
    >
      <transition-group
        type="transition"
        name="flip-list"
      >
        <!-- #region Table -->
        <template v-for="item in tableItems">
          <PlanListCard
            :key="item.id"
            :tableItem="item"
            :canShowTable="canShowTable"
            :reqPutStatus="reqPutStatus"
            class="border-white border rounded p-2 mb-4 with-drag-button"
          />
        </template>
        <!-- #endregion Table -->
      </transition-group>
    </Draggable>

    <!-- #region Alert -->
    <CAlert
      color="danger"
      class="mt-3 text-center"
      v-if="notFound"
    >
      <!-- TODO -->
      表示可能なプランがありません。
    </CAlert>
    <!-- #endregion Alert -->

    <!-- #region Modal -->
    <!-- #endregion Modal -->

  </div>
</template>

<script>
import axios from "axios";
import PlanListCard from "./ListCard";

export default {
  name: "PlanListTable",

  components: {
    PlanListCard,
  },

  props: {},

  computed: {
    /** 一覧表表示できるか？ */
    canShowTable() {
      // return !this.loading && !this.notFound;
      return true; // TODO: 開発用
    },

    /** レスポンスデータが見つからないか？ */
    notFound() {
      return this.response.length < 1;
    },

    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },

    uid() {
      return this.$store.state.uniqueId;
    },
  },

  data() {
    return {
      //#region Flag
      loading: false,

      //#endregion Flag

      //#region Table

      /** 一覧表示データ */
      // tableItems: [],
      // TODO: 開発用
      tableItems: [
        {
          id: "01",
          order: 0,
          code: "plan01",
          name: "プラン01",
          capacity: "1~2",
          baseInventory: 5,
          status: true,
          // useType: "0",
          // salesType: "0",
          planType: 1,
          roomTypes: [
            {
              id: "01",
              code: "wa01",
              name: "和室6畳",
              size: "6畳",
              priceCalendar: "A pattern",
              status: true,
            },
            {
              id: "02",
              code: "wa02",
              name: "和室10畳",
              size: "10畳",
              priceCalendar: "A pattern",
              status: true,
            },
            {
              id: "03",
              code: "yo01",
              name: "洋室15㎡",
              size: "15㎡",
              priceCalendar: "B pattern",
              status: true,
            },
          ],
        },
        {
          id: "02",
          order: 1,
          code: "wa02",
          name: "和室10畳",
          capacity: "2~4",
          baseInventory: 3,
          size: "10畳",
          status: true,
          // useType: "0",
          // salesType: "0",
          planType: 2,
          roomTypes: [
            {
              id: "01",
              code: "wa01",
              name: "和室6畳",
              size: "6畳",
              priceCalendar: "A pattern",
              status: true,
            },
            {
              id: "02",
              code: "wa02",
              name: "和室10畳",
              size: "10畳",
              priceCalendar: "C pattern",
              status: true,
            },
            {
              id: "03",
              code: "yo01",
              name: "洋室15㎡",
              size: "15㎡",
              priceCalendar: "B pattern",
              status: true,
            },
          ],
        },
        {
          id: "03",
          order: 2,
          code: "wa03",
          name: "和室30畳",
          capacity: "2~4",
          baseInventory: 3,
          size: "10畳",
          status: true,
          // useType: "1",
          // salesType: "1",
          planType: 3,
          roomTypes: [
            {
              id: "01",
              code: "wa01",
              name: "和室6畳",
              size: "6畳",
              priceCalendar: "A pattern",
              status: true,
            },
            {
              id: "02",
              code: "wa02",
              name: "和室10畳",
              size: "10畳",
              priceCalendar: "A pattern",
              status: true,
            },
            {
              id: "03",
              code: "yo01",
              name: "洋室15㎡",
              size: "15㎡",
              priceCalendar: "B pattern",
              status: true,
            },
          ],
        },
      ],

      //#endregion Table

      //#region Response data

      response: [],
      activeNames: [],

      //#endregion Response data
    };
  },

  methods: {
    //#region Event
    onSortEnd(event) {
      // TODO: 
      console.log(event);
      this.tableItems.forEach((a, i) => {
        this.tableItems[i].order = i;
      });
    },
    //#endregion Event


    //#region Request
    reqGet() {
      const url = `/rest/hotel/${this.uid}/plan`;

      axios
        .get(url, {
          headers: { Authorization: `Bearer ${this.$keycloak.token}` },
        })
        .then((resp) => {
          const datas = resp.data.plans;
          console.log(datas);

          this.response = datas;

          // 取得データを一覧表に流し込み
          this.pourTable(datas);

          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.PassError = err.response.status;
          this.loading = false;
        });
    },

    reqPutStatus(item) {
      this.loading = true;

      const url = `/rest/hotel/${this.uid}/plan/${this.planId}/status`;
      const body = { status: item.status };

      axios
        .put(url, body, {
          headers: {
            'Authorization': `Bearer ${this.$keycloak.token}`,
            'Accept-Language': this.$i18n.locale,
            // 'Accept-Language': `*`,
          },
        })
        .then((resp) => {
          console.log(JSON.stringify(resp.data));
          this.loading = false;
          this.reqGet();
        })
        .catch((err) => {
          this.loading = false;
          this.errorModal = true;
          this.errorMessage = `${err.name} : ${err.message}`;
          console.log(err);
        });
    },
    //#endregion Request


    //#region Method
    /**
     * 一覧表にデータを流し込む
     * @param datas
     */
    pourTable(datas) {
      this.clearTable();
      datas
        .sort((a, b) => a.displayOrder - b.displayOrder)
        .forEach((data) => {
          this.tableItems.push({
            id: data.id,
            code: data.code,
            name: data.name,
            capacity: `${data.capacityMin} ~ ${data.capacityMax}`,  // TODO
            baseInventory: data.baseInventory, // TODO
            size: data.size,
            status: data.status,
            displayOrder: data.displayOrder,

            hotelId: data.hotelId,
            summary: data.summary,
            description: data.description,
            planType: data.planType,
            max_people: data.max_people,  // TODO
            min_people: data.min_people,  // TODO
            max_nights: data.max_nights,  // TODO
            min_nights: data.min_nights,  // TODO
            images: data.images,  // TODO
            properties: data.properties,  // TODO
            sites: data.sites,  // TODO
            planRooms: data.planRooms,  // TODO
          });
        });
    },

    /**
     * 一覧表をクリア
     */
    clearTable() {
      this.tableItems = [];
    },
    //#endregion Method
  },

  beforeMount() {
    this.reqGet();
  },
};
</script>
