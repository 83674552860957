<template>
  <!-- 
    画面: プラン一覧カード
    用途: プラン一覧の1件のデータを表示する
   -->
  <div>

    <CRow>
      <CCol md="1">
        <CImg
          class="mb-2"
          fluid
          :src="'https://kunozan.jp/news/wp-content/themes/post8/common/img/town_livehouse.jpg'"
        />

      </CCol>
      <CCol md="11">
        <!-- #region Table -->
        <CDataTable
          border
          hover
          size="sm"
          addTableClasses="m-0"
          :fields="tableFields"
          :items="tableItems"
          v-if="canShowTable"
        >

          <!-- #region プランID -->
          <td
            slot="id"
            slot-scope="{ item }"
          >
            <span class="ml-2 text-left">
              {{ item.id }}
            </span>
          </td>
          <!-- #endregion プランID -->

          <!-- #region プランタイプ -->
          <td
            slot="planType"
            slot-scope="{ item }"
          >
            <span class="ml-2 text-left">
              {{ item.planType | planType }}
            </span>
          </td>
          <!-- #endregion プランタイプ -->

          <!-- #region プラン名 -->
          <td
            slot="name"
            slot-scope="{ item }"
          >
            <span class="ml-2 text-left">{{ item.name }}</span>
          </td>
          <!-- #endregion プラン名 -->

          <!-- #region 料金登録機関 -->
          <td
            slot="acceptancePeriod"
            slot-scope="{ item }"
            class="text-center"
          >
            <span class="ml-2 text-left">{{ item.acceptancePeriod }}</span>
          </td>
          <!-- #endregion 料金登録機関 -->

          <!-- #region 掲載期間 -->
          <td
            slot="postingTerm"
            slot-scope="{ item }"
            class="text-center"
          >
            <span class="ml-2 text-left">{{ item.postingTerm }}</span>
          </td>
          <!-- #endregion 掲載期間 -->

          <!-- #region 表示／非表示 -->
          <td
            slot="status"
            slot-scope="{ item }"
            class="text-center p-0"
          >
            <CSwitch
              class="mt-1 swc sw-2"
              color="info"
              :labelOn='$t("common.active")'
              :labelOff='$t("common.inActive")'
              variant="opposite"
              :checked="item.status"
              @update:change="onStatusChanged(item)"
            />
          </td>
          <!-- #endregion 表示／非表示 -->

          <!-- #region アクション -->
          <template #action="{ item }">
            <td class="p-0 text-center">
              <CButton
                class="mt-1"
                color="info"
                square
                size="sm"
                @click="onEditClicked(item.id)"
              ><i class="icon cil-pencil mr-1"></i> {{ $t("common.edit") }}</CButton>
            </td>
          </template>
          <!-- #endregion アクション -->

        </CDataTable>
        <!-- #endregion Table -->

      </CCol>
    </CRow>

    <CRow>
      <CCol>
        <PlanListRoomTypeTable
          :planId="tableItem.id"
          :tableItems="tableItem.planRooms"
          v-if="canShowTable"
        />
        <pre><code>{{tableItem}}</code></pre>
      </CCol>
    </CRow>

  </div>
</template>

<script>
// import axios from "axios";
import i18n from "@/i18n";
import PlanListRoomTypeTable from "./RoomTypeTable";
import STATUS from "@/mixins/property";

export default {
  name: "PlanListCard",

  components: {
    PlanListRoomTypeTable,
  },

  mixins: [STATUS],

  props: {
    /** カード1件分データ */
    tableItem: {
      type: Object,
      required: true,
      // default: {}
    },

    /** カード表示可能か？ */
    canShowTable: {
      type: Boolean,
      required: true,
      default: true,
    },

    /** ステータス更新リクエスト */
    reqPutStatus: {
      type: Function,
      required: true,
    }
  },

  computed: {

    tableItems() {
      return new Array(this.tableItem);
    },

    activeStatus() {
      return this.$v.edit.$invalid ? 'inactive' : 'active'
    },

    uid() {
      return this.$store.state.uniqueId;
    },
  },


  data() {
    return {
      //#region Table

      /** 一覧列名 */
      tableFields: [
        {
          key: "id",
          label: "ID",
          _classes: "text-center bg-light",
          _style: "width:7%",
        },
        {
          key: "planType",
          label: `${i18n.t("planListPage.planType")}`,
          _classes: "text-center bg-light",
          _style: "width:12%",
        },
        {
          key: "name",
          label: `${i18n.t("planListPage.planName")}`,
          _classes: "text-center bg-light",
        },
        {
          key: "acceptancePeriod",
          label: `${i18n.t("planListPage.priceRegistrationPeriod")}`,
          _classes: "text-center bg-light",
          _style: "width:12%",
        },
        {
          key: "postingTerm",
          label: `${i18n.t("planListPage.postingTerm")}`,
          _classes: "text-center bg-light",
          _style: "width:12%",
        },
        {
          key: "status",
          label: `${i18n.t("common.switchActive")}`,
          _classes: "text-center bg-light",
          _style: "width:8%",
        },
        {
          key: "action",
          label: `${i18n.t("common.action")}`,
          _classes: "text-center bg-light",
          _style: "width:8%",
        },
      ],
      //#endregion Table
    };
  },

  methods: {
    //#region Event
    onStatusChanged(item) {
      item.status = this.toggleStatus(item.status);
      this.reqPutStatus(item);
    },

    onEditClicked() {
      const planId = this.tableItem.id;
      this.$router.push({
        // path: `/admin/plan/${planId}/edit`,  // TODO: 開発中
        path: `/operator/plan/${planId}/edit`,
      });
    },
    //#endregion Event

    //#region Method
    /** ステータス切替 */
    toggleStatus(status) {
      return status == this.STATUS.VALID ? this.STATUS.INVALID : this.STATUS.VALID;
    },
    //#endregion Method

  },

};
</script>
