<template>
  <!-- 
    画面: プラン一覧テーブル
    用途: プラン一覧カードを一覧表示する
   -->
  <div>
    <!-- #region Table -->
    <CDataTable
      border
      hover
      size="sm"
      addTableClasses="m-0"
      :fields="tableFields"
      :items="tableItems"
    >
      <td
        slot="name"
        slot-scope="{ item }"
      >
        <span class="ml-2 text-left">{{ item.name }}</span>
      </td>
      <td
        slot="pattern"
        slot-scope="{ item }"
      >
        <span class="ml-2 text-left">{{ item.pattern }}</span>
      </td>
      <td
        slot="status"
        slot-scope="{ item }"
        class="text-center p-0"
      >
        <CSwitch
          class="mt-1 swc sw-2"
          color="info"
          data-on="On"
          data-off="Off"
          :labelOn='$t("common.active")'
          :labelOff='$t("common.inActive")'
          variant="opposite"
          :checked="item.status"
          @change="onStatusChanged()"
        />
      </td>
      <template #action="{ item }">
        <td class=" text-center p-0">
          <CButton
            class="mt-1"
            color="info"
            square
            size="sm"
            @click="onEditClicked(planId, item.roomId)"
          ><i class="icon cil-list mr-1"></i> {{ $t("planListPage.planPrice") }}</CButton>
        </td>
      </template>
    </CDataTable>
    <!-- #endregion Table -->

    <!-- #region Modal -->
    <!-- #endregion Modal -->

  </div>
</template>

<script>
// import axios from "axios";
import i18n from "@/i18n";

export default {
  name: "PlanListRoomTypeTable",

  components: {},

  props: {
    /** 1件のプランに紐づく部屋タイプリスト */
    tableItems: {
      type: Array,
      required: true,
    },

    /** 1件のプランに紐づく部屋タイプリスト */
    planId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      //#region Flag

      loading: false,

      //#endregion Flag

      //#region Table

      /** 一覧列名 */
      tableFields: [
        {
          key: "name",
          label: `${i18n.t("planListPage.roomTypeName")}`,
          _classes: "text-center bg-light"
        },
        {
          key: "pattern",
          label: `${i18n.t("planListPage.priceCalendar")}`,
          _classes: "text-center bg-light"
        },
        {
          key: "status",
          label: `${i18n.t("common.switchActive")}`,
          _classes: "text-center bg-light",
          _style: "width:10%",
        },
        {
          key: "action",
          label: `${i18n.t("common.action")}`,
          _classes: "text-center bg-light",
          _style: "width:10%",
        },
      ],

      //#endregion Table

      //#region Response data

      response: [],

      //#endregion Response data
    };
  },

  computed: {
    /** レスポンスデータが見つからないか？ */
    notFound() {
      return this.response.length < 1;
    },
  },

  methods: {
    //#region Event
    /** 有効無効切替 */
    onStatusChanged() {
      // TODO: 有効無効ステータス更新リクエスト
      this.reqPutStatus();
    },

    /**
     * 編集ボタン押下
     * @param planId
     * @param roomTypeId
     */
    onEditClicked(planId, roomTypeId) {
      this.$router.push({
        // path: `/admin/plan/${planId}/roomType/${roomTypeId}/site`, // TODO: 開発中
        path: `/operator/plan/${planId}/roomType/${roomTypeId}/site`,
      });
    },
    //#endregion Event


    //#region Request
    /** 有効無効ステータス更新 */
    reqPutStatus() {
      // TODO:
    },
    //#endregion Method
  },

  beforeMount() {
    // this.getPlanList(); // TODO: 開発用
  },
};
</script>
<style lang="scss" scoped>
</style>
