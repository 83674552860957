var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CDataTable',{attrs:{"border":"","hover":"","size":"sm","addTableClasses":"m-0","fields":_vm.tableFields,"items":_vm.tableItems},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var item = ref.item;
return _c('td',{},[_c('span',{staticClass:"ml-2 text-left"},[_vm._v(_vm._s(item.name))])])}},{key:"pattern",fn:function(ref){
var item = ref.item;
return _c('td',{},[_c('span',{staticClass:"ml-2 text-left"},[_vm._v(_vm._s(item.pattern))])])}},{key:"status",fn:function(ref){
var item = ref.item;
return _c('td',{staticClass:"text-center p-0"},[_c('CSwitch',{staticClass:"mt-1 swc sw-2",attrs:{"color":"info","data-on":"On","data-off":"Off","labelOn":_vm.$t("common.active"),"labelOff":_vm.$t("common.inActive"),"variant":"opposite","checked":item.status},on:{"change":function($event){return _vm.onStatusChanged()}}})],1)}},{key:"action",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:" text-center p-0"},[_c('CButton',{staticClass:"mt-1",attrs:{"color":"info","square":"","size":"sm"},on:{"click":function($event){return _vm.onEditClicked(_vm.planId, item.roomId)}}},[_c('i',{staticClass:"icon cil-list mr-1"}),_vm._v(" "+_vm._s(_vm.$t("planListPage.planPrice")))])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }